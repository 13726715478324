{/*eslint-disable */}
import React, { Component } from 'react';
import Layout from '../layout/Layout';

export default class PetProjects extends Component {

	render() {
		return (
			<Layout>
				<div className='train'>
					<p>
						Raspberry Pi
						<br />
						Garam App
						<br />
						Gift App
						<br />
						Design Posters
						<br />
						Rubix Cube
						<br />
						Coding
					</p>	
				</div>
			</Layout>
		)
	}
}
